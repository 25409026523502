import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";

const HowItWorksSummary = () => {
    return (
        <div className="py-10 text-black bg-relay-green-background lg:py-20">
            <section className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
                <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-green-primary">How it works</h1>
                <p className="text-base font-semibold lg:text-2xl">No app, no sign up required by the driver.</p>
                <p className="text-base font-semibold lg:text-2xl">Just scan and securely join the wait list.</p>
            </section>
            <section className="flex flex-col max-w-screen-lg mx-4 my-10 lg:mx-auto lg:flex-row">
                <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                    <StaticImage 
                        height={90}
                        width={90}
                        placeholder="tracedSVG"
                        src="../images/icon-checkin.svg" 
                        alt="Check In"/>
                    <h2 className="text-3xl font-bold text-relay-green-secondary">Check in</h2>
                    <p className="my-2 text-base-lg">
                        On arrival simply scan the QR code displayed at the the connector. 
                        We securely store your details with us and ensure it’s never shared with other drivers.
                    </p>
                </div>
                <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                    <StaticImage 
                        height={90}
                        width={90}
                        placeholder="tracedSVG"
                        src="../images/icon-notification.svg" 
                        alt="Get Notified"/>
                   <h2 className="text-3xl font-bold text-relay-green-secondary">Get notified</h2>
                    <p className="my-2 text-base-lg">
                        We keep you posted via SMS when the charger becomes available.
                        No apps, signups necessary, just scan and let us keep you updated.
                    </p>
                </div>
                <div className="lg:w-1/3">
                    <StaticImage
                        height={90}
                        width={90}
                        placeholder="tracedSVG"
                        src="../images/icon-charge.svg" 
                        alt="Charge"/>
                    <h2 className="text-3xl font-bold text-relay-green-secondary">Charge up</h2>
                    <p className="my-2 text-base-lg">
                        Charge your car when it's your turn.
                        Let us know how long you’ll be, so we can keep the others posted.
                    </p>
                </div>
            </section>
            <div className="flex flex-col max-w-screen-md mx-auto">
                <Link className="px-10 py-4 mx-auto mt-10 text-xl border-2 rounded-full text-relay-green-primary border-relay-green-primary" to="/how-it-works">
                    Read more
                </Link>
            </div>
        </div>
    );
};

export default HowItWorksSummary;