import * as React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import StripeClimate from "../components/StripeClimate"
import HowItWorksSummary from "../components/HowItWorksSummary"
import Featured from "../components/Featured"
import Seo from "../components/Seo"

const IndexPage = () => {
  const socialImage = {
    src: "/social.jpg",
    height: 1500,
    width: 3000
  }
  return (
    <Layout>
      <Seo 
        title="EV charging concierge"
        description="Privacy focused EV charging concierge for destinations"
        image={socialImage}
      />
      <Hero/>
      <HowItWorksSummary/>
      <Featured/>
      <StripeClimate/>
    </Layout>
  )
}

export default IndexPage
