import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const StripeClimate = () => {
    const data = useStaticQuery(graphql`
        query {
            BadgeStripeClimate: file(relativePath: { eq: "badge-stripe-climate.svg" }) {
                publicURL
            },
        }
    `);

    return (
        <section className="flex flex-col p-8 mb-20 items-center text-gray-600">
            <img src={data.BadgeStripeClimate.publicURL} className="w-16" alt="Stripe Climate"/>
            <p className="py-4  text-center text-lg lg:text-xl">Relay will contribute <b>1% of your spend</b> to remove CO₂ from the atmosphere.</p>
            <a className="flex items-center text-base lg:text-lg" href="https://www.stripe.com/climate/">
                Learn about Stripe Climate
                <svg className="w-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
            </a>
        </section>
    );
};

export default StripeClimate;