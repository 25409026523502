import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

const Featured = () => {
    return (
        <div className="py-10 text-black bg-relay-blue-background lg:py-20">
            <section className="flex flex-col max-w-screen-md mx-4 lg:mx-auto">
                <h1 className="text-5xl font-bold text-relay-blue-primary">
                    Featured
                </h1>
                <p className="mb-4 text-5xl font-extrabold">
                    Bannisters Mollymook
                </p>
                <p className="text-base font-semibold lg:text-2xl">
                Our friends at Bannisters are our first test site. They have been offering destination charging since the early days.
                </p>
            </section>
            <div className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto">
                <StaticImage 
                    placeholder="tracedSVG"
                    src="../images/bannisters.png" 
                    alt="Get Notified"/>
            </div>
            <div className="flex max-w-screen-md mx-4 lg:mx-auto">
                <a className="px-10 py-4 mt-10 text-xl border-2 rounded-full text-relay-blue-primary border-relay-blue-primary" href="https://bannisters.com.au">
                    Read more
                </a>
            </div>
        </div>
    );
};

export default Featured;