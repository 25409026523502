import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
    return (
        <section className="flex flex-col max-w-full lg:max-w-screen-lg lg:mx-auto mt-14">
            <h1 className="mx-auto text-3xl font-extrabold text-center lg:max-w-screen-md lg:text-5xl">
                Privacy focused EV charging concierge for destinations
            </h1>
            <a className="px-10 py-4 mx-auto mt-10 text-xl border-2 rounded-full text-relay-green-primary border-relay-green-primary" href="https://confirmsubscription.com/h/d/05B469E3572052F4">
                Early access
            </a>
            <StaticImage 
                placeholder="tracedSVG"
                src="../images/hero.png" 
                alt="Your destination with peace of mind for vehicle charging"/>
        </section>
    );
};

export default Hero;